<template>
  <div class="container" style="width: 100%; max-width: 800px;">
    <img src="/4p_logo.png" style="width: 100%; max-width: 640px">
    <h1 style="font-size:2.4rem; margin-top: 40px;">Feliz Natal da 4P!</h1>

    <div style="padding-top:56.25%; position: relative; " v-if="hash">
      <iframe style="position:absolute;top:0;left:0;width:100%;height:100%;"
        :src="`https://www.youtube.com/embed/${hash}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1`"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>

  </div>
</template>

<script>
  export default {
    components: {

    },
    props: {

    },
    data() {
      return {
        path: window.location.pathname,
        videos: {
          // '/': 'yPNiFPfLWPY',
          '/4p/bruna': 'rpPPhpxAvR4',
          '/4p/bruno': 'YEyCu4irdyw',
          '/4p/cassio': 'jIHeQNQjAVU',
          '/4p/emerson': 'RMdjUiB2NNk',
          '/4p/livia': 'mns6udUEIf8',
          '/4p/karen': 'Bf6Pv44-Gms',
          '/4p/natalia': 'Wx5Ry_UxvPQ',
          '/4p/yago': 'O2Hh12dXrFQ',
          '/4p/arthur': 'XORfqNiUGZA',
          '/4p/luis': 'yPNiFPfLWPY',
          '/4p/adriana': 'EN-yL7ZDsFs',
          '/4p/lucas': '9_5aRGgV534',
          '/4p/sthefani': 'Jw21NWzIKmE',
          '/4p/nathalia': '8m95Ut24TgE',
          '/4p/daiane': 'mkAIbUS6B_k',
          '/4p/will': 'f72NZ2dpWa8',
          '/bcar/darleidy': 'bxvJOyTnzlM',
          '/bcar/amanda': 'h_yxsA7omZo',
          '/bcar/chirlene': 'YBR2vqy-qGg',
          '/bcar/fabiana': 'Kqg_lk8yhVM',
          '/bcar/italo-cleber': 'k57b0Xx8MtQ',
          '/bcar/italo-gabriel': 'NY2lbohvDFk',
          '/bcar/jhon': 'j66hW_sOdGk',
          '/bcar/nathalya': 'aJaxZ9ryvgQ',
          '/bcar/rafael': 'rJ1s9Vt4rYQ',
          '/bcar/aliston': '7VSWa5I2rV4',
          '/bcar/thadeu': 'wx02v8Ij2xY',
          '/bcar/thiago': 'qMDlIz1NnS8',
          // '/bcar/daniely': 'aa',
          '/bp/eduardo': 'stPOsrELRC4',
          '/bp/silverio': 'vvFVixN4sGw',
          '/bp/rodrigo': '0nEK4jZkM6U',
          '/bp/rone': 'YZzW1_ceVZM',
          '/bp/cleyber': 'BVoXy7T-Ds0',
          '/bp/paulo': 'FiXa4sLSwXM',
          '/bp/renato': 'Z5yoCXgE6NA', //8lLWKu55AGo
          '/bp/luana': 'M4vxjNz1lIM',
          '/bp/brunna': 'gzi6LPjbDg4',
          '/bp/guilherme': 'w_p7MIICsww',
          '/bp/leandra': 'q2pvbme8ZiA',
          '/bp/thiago': 'mKSk-GOiTHk',
          '/bp/calebe': 'feDwLe5-bCk',
          '/flug/ana-caroline': '0zM8KEUm-FA',
          '/flug/ana-carolina': 'BfKVgDcq0uk',
          '/flug/arthur': 'iXcs4whQaZY',
          '/flug/felipe': 'i1nZkwI4XBI',
          '/flug/guilherme': 'LXS9-2nzLHU',
          '/flug/isabela': 'pqfaqn6aLk0',
          '/flug/isabele': 'N8XG1tr9sBg',
          '/flug/jorge': 'Tunj3vEIllk',
          '/flug/julia-silva': 'xL4MF07wNqo',
          '/flug/julia-costa': '_llLC65ioR4',
          '/flug/julie': 'lqfzoCMN27g',
          '/flug/katherine': '3Br1VfBHcQk',
          '/flug/matheus': 'tB0G7Md7fPc',
          '/flug/pedro': '7nrrI2kG5jA',
          '/spiti/hudson': 'VRu7i3LkAPk',
          '/spiti/daniela': 'Mh6Dyg0X8rY',
          '/spiti/pedro': '7WyUlI-0Moc',
          '/spiti/thiago': 'dZRIzjKKaRE',
          '/spiti/valberth': 'Di0dUy33c4E',
          '/spiti/cassio': 'CEgAnopzBv8',
          '/hi9/rodrigo': 'IrjhWWL_I6Q',
          '/bf/fernando': 'sW87ZM4zZ4A',
        }


      }
    },
    computed: {
      hash() {
        return this.videos[this.path];
      }
    },
    mounted() {

    },
    watch: {

    },
    methods: {

    }
  }
</script>
